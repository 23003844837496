<template>
  <section class="cabin-list">
    <row container :gutter="20">
      <column v-for="item in cabins" :key="item.name" :xs="12" :md="6" :lg="4">
        <Cabin :cabin="item"></Cabin>
      </column>
      <column :xs="12" :md="6" :lg="4">
        <div class="add-cabin">
          <router-link :to="{ name: 'AddCabin' }">
            <Button text="Legg til ny hytte" color="green" symbol="plus"></Button>
          </router-link>
        </div>
      </column>
    </row>
  </section>
</template>

<script>
import Cabin from '@/components/Cabin';
import Button from '@/components/Button';
import CabinService from '@/services/CabinService';
import store from '@/store';

export default {
  components: {
    Cabin,
    Button
  },
  data() {
    return {
      cabins: []
    };
  },
  async beforeRouteEnter(to, from, next) {
    const { data } = await CabinService.getCabins(
      store.getters['users/userId']
    );
    next(vm => {
      vm.cabins = data;
    });
  }
};
</script>

<style lang="scss" scoped>
.cabin-list {
  margin-top: 1rem;
  margin-bottom: 4rem;
  @include gutter-padding();
}
.add-cabin {
  background-color: #bed0cb;
  height: 264px;
  border-radius: 8px;
  display: flex;
  justify-content: center;
  align-items: center;
}
</style>
