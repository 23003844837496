<template>
  <div class="cabin-card">
    <router-link :to="{ name: 'hytte', params: { cabinId: cabin.id }}">
      <div class="cabin-card_image" :style="{ 'background-image': `url(${getCabinImage()})` }"></div>
      <div class="cabin-card_content">
        <h2>{{ cabin.name }}</h2>
      </div>
    </router-link>
  </div>
</template>

<script>
import Helpers from '@/helpers/helpers';

export default {
  name: 'Cabin',
  props: {
    cabin: Object
  },
  methods: {
    createUrlForCustomSize: Helpers.createUrlForCustomSize,
    getCabinImage() {
      if(this.cabin.content.length) {
        return `${this.createUrlForCustomSize(this.cabin.content[0].storageUrl, 'medium')}${this.cabin.storageTokenQuery}`;
      }
      return false;
    }
  }
};
</script>

<style lang="scss" scoped>
.cabin-card {
  background: #f0f1f0;
  box-shadow: 0px 22px 54px -20px rgba(25, 25, 26, 0.200578);
  border-radius: 8px;
  a {
    text-decoration: none;
  }
  &_image {
    background-image: url('../assets/dummy_cabin.png');
    background-size: cover;
    height: 200px;
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
  }
  &_content {
    position: relative;
    padding: 1.5rem;
    h2 {
      text-decoration: none;
      color: black;
      font-weight: 600;
      margin-bottom: 0;
    }
  }
}
</style>
